import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import MeritHeader from '../components/about/MeritHeader';

const Index = () => (
  <Layout>
    <Seo title='Home' keywords={[`gatsby`, `application`, `react`]} />
    <MeritHeader>Work In Progress</MeritHeader>
    <h2>We're building something great</h2>
  </Layout>
);

export default Index;
